<template>
    <section class="registration" id="registration">
        <!-- <a :href="downloadLink" class="registration__head-rules" download>{{ rules }}</a> -->
        <div class="registration__content">
            <div class="container">
                <h2 class="registration__title">{{ title }}</h2>
                <form class="registration__wrapper" autocomplete="off" method="POST" novalidate
                    v-on:submit.prevent="onSubmit">

                    <FormInput :input-spec="surnameSpec" @input="validateSurname" />
                    <FormInput :input-spec="nameSpec" @input="validateName" />

                    <FormInput v-if="language === 'ru'" :input-spec="patronymSpec" />

                    <FormInput :input-spec="universitySpec" @input="validateUniversity" />
                    <FormInput :input-spec="facultySpec" @input="validateFaculty" />
                    <FormInput :input-spec="courseSpec" @input="validateCourse" />
                    <FormInput :input-spec="yearSpec" @input="validateYear" />
                    <FormInput :input-spec="countrySpec" @input="validateCountry" />

                    <FormInput :input-spec="citySpec" @input="validateCity" />
                    <FormInput :input-spec="emailSpec" @input="validateEmail" />

                    <FormInput :input-spec="phoneSpec" @input="validatePhone" />

                    <FormTextarea :input-spec="textSpec" />

                    <div class="registration__upload">
                        <div class="registration__upload-file-list">
                            <div v-for="item in uploadedFiles" class="registration__upload-file-item int">
                                <span>{{ item.name }}</span>
                                <div class="registration__upload-file-item-cross" @click="deleteFile(item.name)">
                                    <IconCross />
                                </div>
                            </div>
                        </div>
                        <div class="registration__upload-wrapper">
                            <label for="doc_upload" class="registration__upload-label int">
                                <IconUpload />
                                <span v-if="language === 'ru'">Прикрепить файл</span>
                                <span v-else>Upload file</span>
                            </label>
                            <input id="doc_upload" ref="upload" type="file" name="doc_upload" multiple accept=".docx"
                                class="registration__upload-input" @change="uploadFiles">
                        </div>
                    </div>

                    <div :class="{ '_active': checkbox1Active }" class="registration__checkbox" @click="onClick(1)">
                        <div class="registration__checkbox-wrapper">
                            <IconChecked v-if="checkbox1Active" />
                            <IconUnchecked v-else />
                        </div>
                        <p class="registration__checkbox-text">
                            <span v-if="language === 'ru'">Я ознакомлен с Положением о проведении настоящего
                                конкурса*</span>
                            <span v-else>I accept the rules of this contest*</span>
                        </p>
                    </div>
                    <div :class="{ '_active': checkbox2Active }" class="registration__checkbox" @click="onClick(2)">
                        <div class="registration__checkbox-wrapper">
                            <IconChecked v-if="checkbox2Active" />
                            <IconUnchecked v-else />
                        </div>
                        <p class="registration__checkbox-text">
                            <span v-if="language === 'ru'">Я готов предоставить документы, подтверждающие мою
                                академическую
                                успеваемость и отсутствие задолженности, по требованию организаторов конкурса*</span>
                            <span v-else>I am prepared to provide additional documents confirming my academic
                                achievements,
                                if requested by the Organizing Committee*</span>
                        </p>
                    </div>
                    <div :class="{ '_active': checkbox3Active }" class="registration__checkbox" @click="onClick(3)">
                        <div class="registration__checkbox-wrapper">
                            <IconChecked v-if="checkbox3Active" />
                            <IconUnchecked v-else />
                        </div>
                        <p class="registration__checkbox-text">
                            <span v-if="language === 'ru'">Я даю согласие на обработку моих персональных данных*</span>
                            <span v-else>Consent on personal data processing*</span>
                        </p>
                    </div>


                    <button class="registration__submit button primary" type="submit"
                        :class="{ '_disabled': !formValidated, '_loading': buttonLoading }" :disabled="!formValidated">
                        <PointsLoading v-if="buttonLoading" />
                        <div v-else>
                            <span v-if="language === 'ru'">Отправить</span>
                            <span v-else>Send request</span>
                        </div>
                    </button>

                    <FormResult :error-active="errorActive" :success-active="successActive" @click="closeResult" />
                </form>
            </div>
        </div>
    </section>
</template>

<script setup>
import { currentHost } from "@/api/hosts";
import { reactive, computed, watch, ref, onMounted } from "vue";
import FormInput from "../vue-ui/FormInput";
import FormSelect from "../vue-ui/FormSelect";
import FormResult from "../blocks/FormResult";
import FormTextarea from "../vue-ui/FormTextarea"
import PointsLoading from "../vue-ui/PointsLoading.vue"
import IconUpload from "../../assets/images/icons/IconUpload";
import IconCross from "../../assets/images/icons/IconCross";
import IconChecked from "@/assets/images/icons/IconChecked";
import IconUnchecked from "@/assets/images/icons/IconUnchecked";
import { useStore } from "vuex";
import Api from "../../api/api"
// import { useReCaptcha } from "vue-recaptcha-v3";
// const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const name = "RegistrationForm";
const store = useStore();
const api = new Api();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const title = computed(() => data.competition.registration.title);
const placeholders = computed(() => data.competition.registration.placeholders);
const messages = computed(() => data.competition.registration.messages);

const SITE_KEY = '6Lc5mCYqAAAAACx6GKBdtZKvzyCdYBfrWlb0xd-e'

let downloadLink;

if (language.value === 'ru') {
    downloadLink = `${currentHost}download/ru`;
} else {
    downloadLink = `${currentHost}download/en`;
}

let formValidated = ref(false);
let successActive = ref(false);
let errorActive = ref(false);

let buttonLoading = ref(false);

let checkbox1Active = ref(false)
let checkbox2Active = ref(false)
let checkbox3Active = ref(false)

let upload = ref(null);
let uploadedFiles = ref([])


const surnameSpec = reactive({
    model: '',
    name: 'surname',
    placeholder: placeholders.value['surname'],
    error: false,
    errorMsg: '',
    validated: false,
})

const nameSpec = reactive({
    model: '',
    name: 'name',
    placeholder: placeholders.value['name'],
    error: false,
    errorMsg: '',
    validated: false,
})

const patronymSpec = reactive({
    model: '',
    name: 'patronym',
    placeholder: placeholders.value['patronym'],
    error: false,
    errorMsg: '',
    validated: false,
})

const universitySpec = reactive({
    model: '',
    name: 'university',
    placeholder: placeholders.value['university'],
    error: false,
    errorMsg: '',
    validated: false,
})

const facultySpec = reactive({
    model: '',
    name: 'faculty',
    placeholder: placeholders.value['faculty'],
    error: false,
    errorMsg: '',
    validated: false,
})

const courseSpec = reactive({
    model: '',
    name: 'course',
    placeholder: placeholders.value['course'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[0-9]{1,1}$/
})

const yearSpec = reactive({
    model: '',
    name: 'year',
    placeholder: placeholders.value['year'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[0-9]{4,4}$/
})

const countrySpec = reactive({
    model: '',
    name: 'country',
    placeholder: placeholders.value['country'],
    error: false,
    errorMsg: '',
    validated: false,
})

const citySpec = reactive({
    model: '',
    name: 'city',
    placeholder: placeholders.value['city'],
    error: false,
    errorMsg: '',
    validated: false,
})

const emailSpec = reactive({
    model: '',
    name: 'e_mail',
    placeholder: placeholders.value['email'],
    error: false,
    errorMsg: '',
    validated: false,
    regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
})

const phoneSpec = reactive({
    model: '',
    name: 'phone',
    placeholder: placeholders.value['phone'],
    error: false,
    errorMsg: '',
    validated: true,
    regexp: /^[+]?[0-9]{9,15}$/
})

const textSpec = reactive({
    model: '',
    name: 'text',
    placeholder: placeholders.value['text'],
})


const validate = (obj) => {
    obj['error'] = false;
    obj['errorMsg'] = '';

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateWithRegexp = (obj, msg) => {
    obj['error'] = false;
    obj['errorMsg'] = '';
    obj['validated'] = false;

    if (obj['model'] === '') {
        obj['error'] = true;
        obj['errorMsg'] = messages.value['empty'];
        obj['validated'] = false;
    } else if (!(obj['regexp'].test(obj['model']))) {
        obj['error'] = true;
        obj['errorMsg'] = msg;
        obj['validated'] = false;
    } else {
        obj['validated'] = true;
    }
}

const validateSurname = () => {
    validate(surnameSpec)
}

const validateName = () => {
    validate(nameSpec)
}

const validateUniversity = () => {
    validate(universitySpec)
}

const validateFaculty = () => {
    validate(facultySpec)
}

const validateCourse = () => {
    validate(courseSpec)
    //validateWithRegexp(courseSpec, messages.value['year'])
}

const validateYear = () => {
    validateWithRegexp(yearSpec, messages.value['year'])
}

const validateCountry = () => {
    validate(countrySpec)
}

const validateCity = () => {
    validate(citySpec)
}

const validateEmail = () => {
    validateWithRegexp(emailSpec, messages.value['email'])
}

const validatePhone = () => {
    validateWithRegexp(phoneSpec, messages.value['phone'])
}


const onClick = (num) => {

    if (num === 1) {
        checkbox1Active.value = !checkbox1Active.value;
    } else if (num === 2) {
        checkbox2Active.value = !checkbox2Active.value;
    } else {
        checkbox3Active.value = !checkbox3Active.value;
    }
};

const uploadFiles = () => {
    uploadedFiles.value = [];
    for (let i = 0; i < upload.value.files.length; i++) {
        uploadedFiles.value.push(upload.value.files[i])
    }

}

const deleteFile = (name) => {
    let dt = new DataTransfer();

    for (let i = 0; i < upload.value.files.length; i++) {
        if (upload.value.files[i].name !== name) {
            dt.items.add(upload.value.files[i]);
        }

    }

    upload.value.files = dt.files
    console.log(dt.files)

    uploadFiles()
}


const onSubmit = () => {
    postData()
    // recaptcha()
}

const postData = async () => {
    successActive.value = false;
    errorActive.value = false;

    buttonLoading.value = true;

    const token = await executeRecaptcha();

    let formData = new FormData();

    formData.append('surname', surnameSpec.model);
    formData.append('name', nameSpec.model);
    formData.append('patronym', patronymSpec.model);
    formData.append('university', universitySpec.model);
    formData.append('faculty', facultySpec.model);
    formData.append('course', courseSpec.model);
    formData.append('year', yearSpec.model);
    formData.append('country', countrySpec.model);
    formData.append('city', citySpec.model);
    formData.append('email', emailSpec.model);
    formData.append('phone', phoneSpec.model);
    formData.append('text', textSpec.model);
    formData.append('lang', language.value);
    formData.append('token', token);

    for (let i = 0; i < upload.value.files.length; i++) {
        formData.append('files', upload.value.files[i])
    }

    api.postData(
        formData
    ).then((status) => {
        if (status === 200) {
            successActive.value = true;
        } else {
            errorActive.value = true;
        }
        buttonLoading.value = false;
        console.log(status);
    });
}

const closeResult = () => {
    successActive.value = false;
    errorActive.value = false;
}

const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' })
                .then(token => resolve(token))
                .catch(err => reject(err));
        } else {
            reject('reCAPTCHA не загружен');
        }
    });
};

const createCaptcha = () => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
    document.head.appendChild(script);
}

// const recaptcha = async () => {
//     // Execute reCAPTCHA with action "login".
//     await recaptchaLoaded();
//     const token = await executeRecaptcha('login')

//     postData(token)
//     // Do stuff with the received token.
// }

onMounted(() => {
    createCaptcha()
}),


    watch([surnameSpec, nameSpec, universitySpec, facultySpec, courseSpec, yearSpec, countrySpec, citySpec, emailSpec, phoneSpec, checkbox1Active, checkbox2Active, checkbox3Active, uploadedFiles], (newValue, oldValue) => {
        if (surnameSpec.validated && nameSpec.validated && universitySpec.validated && facultySpec.validated && courseSpec.validated && yearSpec.validated && countrySpec.validated && citySpec.validated && emailSpec.validated && phoneSpec.validated && checkbox1Active.value && checkbox2Active.value && checkbox3Active.value && uploadedFiles.value.length>0) {
            formValidated.value = true;
        } else {
            formValidated.value = false;
        }
    })


</script>

<style lang="scss">
@import '../../styles/global.scss';

.registration {
    width: 100%;
    background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);
    padding: 100px 0;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 50px 0 40px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 50px 0 40px;
    }

    &__content {
        width: 100%;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 35px;
        color: #FFFFFF;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 15px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 20px;
        }
    }

    &__wrapper {
        position: relative;
        width: 695px;
        box-sizing: border-box;
        padding-bottom: 45px;
        margin: 0 auto;

        @media screen and (max-width: ($mobile + 1)) {
            width: 100%;
            padding: 20px 0;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 100%;
            padding: 30px 0;
            max-width: 700px;
        }
    }

    &__upload {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

        @media screen and (max-width: $tablet-middle) {
            flex-direction: column-reverse;
        }

        &-file-list {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;

            @media screen and (max-width: $tablet-middle) {
                flex-direction: row;
                justify-content: center;
            }
        }

        &-file-item {
            position: relative;
            width: 202px;
            height: 28px;
            background: #81C0F8;
            padding: 2px 31px 3px 15px;
            box-sizing: border-box;
            border-radius: 36px;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            margin-left: 13px;
            margin-bottom: 13px;
            display: flex;
            align-items: center;
            color: #FFFFFF;

            @media screen and (max-width: $tablet-middle) {
                margin-left: 0;
            }

            & span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:nth-child(2n) {
                margin-left: 0;
            }

            &-cross {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                right: 10px;
                width: 14px;
                height: 14px;
                padding: 0;
                background-color: transparent;
                transform: translateY(-50%);
                border: none;
                outline: none;
                cursor: pointer;

                & svg {
                    width: 8px;
                    height: 8px;
                }

                & svg path {
                    transition: all .3s ease;
                    fill: #FFFFFF;
                }
            }
        }

        &-wrapper {
            position: relative;
            height: 28px;
            margin-left: 20px;
            margin-bottom: 13px;
            cursor: pointer;

            @media screen and (max-width: $tablet-middle) {
                margin-left: 0;
            }
        }

        &-label {
            position: relative;
            width: 183px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            margin-left: auto;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            padding: 0;
            z-index: 2;

            & svg {
                margin-right: 15px;

                @media screen and (max-width: $tablet-middle) {
                    margin-right: 7px;
                }

                & path {
                    transition: all .3s ease;
                }
            }

            @media screen and (max-width: $tablet-middle) {
                font-size: 14px;
                line-height: 16px;
                width: auto;
            }
        }

        &-input {
            position: absolute;
            top: 0;
            right: 0;
            width: 183px;
            height: 28px;
            padding: 0;
            border: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__checkbox {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin: 0 auto 30px;
        align-items: center;
        cursor: pointer;
        transition: color .3s ease;

        &:first-child {
            margin-top: 62px;

            @media screen and (max-width: $mobile) {
                margin-top: 35px;
            }
        }

        @media screen and (max-width: $mobile) {
            margin: 0 auto 30px;
        }

        &-wrapper {
            width: 20px;
            height: 20px;
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #FFFFFF;
            margin-left: 16px;
        }
    }

    &__submit {
        width: 256px;
        margin: 0 auto;

        @media screen and (max-width: $mobile) {
            width: 190px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            width: 245px;
        }

        &._loading {
            pointer-events: none;
        }
    }

    &__captcha {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
}
</style>