<template>
    <div class="main contest">
        
        <ContestHead :key="language"/>
        <ContestRules :key="language"/>
        <Registration :key="language"/>  
    </div>
</template>

<script setup>
import ContestHead from "../components/Contest/ContestHead.vue"
import ContestRules from "../components/Contest/ContestRules.vue"
import Registration from "../components/Contest/Registration.vue"
import { useStore } from "vuex";
import { ref, computed, provide } from "vue";

const name = "Contest";

const store = useStore();

const language = computed(() => store.state.currentLanguage);

</script>

<style lang="scss"></style>    