<template>
    <div class="contest-rules">
        <div class="container">
            <h2 class="contest-rules__title">{{ title }}</h2>
            <div v-for="(item, index) in about" class="contest-rules__about" :key="`item-${index}`">
                <p class="contest-rules__subtitle">{{ item.title }}</p>
                <p v-for="(str, ind) in item.text" class="contest-rules__about-text" :key="`item-${index}-${ind}`"
                    v-html="str"></p>
            </div>

            <div class="contest-rules__requirements">
                <p class="contest-rules__subtitle">{{ requirements.title }}</p>
                <p v-for="str in requirements.text" class="contest-rules__list-text" v-html="str"></p>
            </div>

            <div class="contest-rules__design">
                <p class="contest-rules__subtitle">{{ design.title }}</p>
                <p v-for="str in design.text" class="contest-rules__list-text" v-html="str"></p>
            </div>

            <div class="contest-rules__wrap">
                <a href="mailto:efimov-konf@vavt.ru" class="contest-rules__link">{{ links.mail }}</a>
                <a :href="downloadLink" class="contest-rules__link" download>{{ links.download }}</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { currentHost } from "@/api/hosts";

const name = "ContestRules";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const title = computed(() => data.competition.rules.title);

const about = computed(() => data.competition.rules.about);
const requirements = computed(() => data.competition.rules.requirements);
const design = computed(() => data.competition.rules.design);

const links = computed(() => data.competition.rules.links);

let downloadLink;

if (language.value === 'ru') {
    downloadLink = `${currentHost}download/ru`;
} else {
    downloadLink = `${currentHost}download/en`;
}

</script>

<style lang="scss">
@import '../../styles/global.scss';

.contest-rules {
    padding: 100px 0 40px;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 40px 0;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding: 40px 0;
    }

    &__title {
        max-width: 865px;
        font-size: 38px;
        font-weight: 500;
        line-height: 50px;
        text-align: center;
        margin: 0 auto 56px;
        color: #2B3D8D;

        @media screen and (max-width: ($mobile + 1)) {
            display: none;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 30px;
            max-width: 544px;
        }
    }

    &__about {
        margin-bottom: 30px;

        @media screen and (max-width: ($mobile + 1)) {
            margin-bottom: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            margin-bottom: 22px;
        }


        &-text {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: left;

            @media screen and (max-width: ($mobile + 1)) {
                font-size: 13px;
                line-height: 18px;
            }

            @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &__requirements,
    &__design {
        margin-bottom: 30px;

        @media screen and (max-width: ($mobile + 1)) {
            margin-bottom: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            margin-bottom: 22px;
        }
    }

    &__subtitle {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        color: #2B3D8D;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 13px;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__list-text {
        position: relative;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding-left: 30px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 13px;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }

        &:before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 11px;
            left: 7px;
            background-color: #333;
            border-radius: 50%;
        }
    }

    &__wrap {
        display: flex;
        width: fit-content;
        margin: 0 auto;
    }

    &__link {
        font-size: 24px;
        font-weight: 400;
        line-height: 50px;
        text-align: center;
        color: #4FA2D6;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 13px;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            line-height: 22px;
        }

        &:first-of-type {
            margin-right: 30px;
        }
    }


}
</style>