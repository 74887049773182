<template>
    <div class="main directions">
        <div class="container">
            <h2 class="directions__title">{{ title }}</h2>
            <ul class="directions__list">
                <li v-for="(item, index) in list" class="directions__item" :key="`item-${index}`">
                    <div class="directions__item-icon"
                        :style="{ backgroundImage: 'url(' + getImgUrl(item.icon) + ')' }">
                    </div>
                    <div class="directions__item-wrap">
                        <p class="directions__item-name">{{ item.item_name }}</p>
                        <p v-for="(str, ind) in item.text" class="directions__item-text" :key="`item-${index}-${ind}`"
                            v-html="str"></p>
                    </div>
                </li>
            </ul>
            <ul class="directions__list _mobile">
                <li v-for="(item, index) in list" class="directions__item" :key="`item-${index}`">
                    <div class="directions__item-wrap">
                        <div class="directions__item-icon"
                            :style="{ backgroundImage: 'url(' + getImgUrl(item.icon) + ')' }">
                        </div>
                        <p class="directions__item-name">{{ item.item_name }}</p>
                    </div>

                    <p v-for="(str, ind) in item.text" class="directions__item-text" :key="`item-${index}-${ind}`"
                        v-html="str"></p>

                </li>
            </ul>
        </div>

    </div>
</template>

<script setup>
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex"

const name = "Directions";

const store = useStore();
const data = store.state.currentData;

const title = computed(() => data.main.directions.title);
const list = computed(() => data.main.directions.list);

const getImgUrl = (pic) => {
    return require('../../assets/images/' + pic);
};
</script>

<style lang="scss">
@import '../../styles/global.scss';

.directions {
    padding: 100px 0 60px;

    @media screen and (max-width: ($mobile + 1)) {
        padding: 20px 0 10px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
        padding: 40px 0 40px;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 60px;
        color: #2B3D8D;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            text-align: center;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    &__list {
        list-style: none;
        padding: 0;

        @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
            max-width: 720px;
            margin: 0 auto;
        }

        &._mobile {
            display: none;
        }

        @media screen and (max-width: $tablet-middle) {
            display: none;

            &._mobile {
                display: block;

                & .directions__item {
                    display: block;
                    margin-bottom: 20px;
                }

                & .directions__item-wrap {
                    display: flex;
                    width: 100%;
                    margin-bottom: 10px;
                }

                & .directions__item-icon {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    margin-right: 32px;

                    @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
                        width: 75px;
                        height: 75px;
                        margin-right: 39px;
                    }

                    &:before {
                        width: 21px;
                        height: 1px;
                        top: 11px;
                        left: 60px;

                        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
                            left: 75px;
                        }
                    }

                    &:after {
                        width: 9px;
                        height: 9px;
                        top: 7px;
                        left: 76px;

                        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
                            width: 12px;
                            height: 12px;
                            left: 90px;
                            top: 6px;
                        }
                    }
                }

                & .directions__item-name {
                    width: calc(100% - 92px);
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: left;
                    margin-top: 3px;

                    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                        width: calc(100% - 107px);
                        font-size: 20px;
                        line-height: 28px;
                    }
                }

                & .directions__item-text {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: left;
                    margin-bottom: 15px;

                    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        margin-bottom: 15px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__item {
        width: 100%;
        display: flex;
        margin-bottom: 40px;

        @media screen and (max-width: ($mobile + 1)) {}

        &-icon {
            position: relative;
            width: 112px;
            height: 112px;
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 63px;

            @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                width: 95px;
                height: 95px;
                margin-right: 53px;
            }

            &:before {
                position: absolute;
                content: '';
                width: 40px;
                height: 1px;
                background-color: #3773B3;
                top: 21px;
                left: 112px;

                @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                    width: 34px;
                    top: 18px;
                    left: 95px;
                }
            }

            &:after {
                position: absolute;
                content: '';
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background-color: #3773B3;
                top: 13px;
                left: 143px;

                @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                    width: 14px;
                    height: 14px;
                    top: 11.5px;
                    left: 121px;
                }
            }
        }

        &-wrap {
            width: 885px;

            @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                width: 532px;
            }
        }

        &-name {
            font-size: 30px;
            font-weight: 600;
            line-height: 44px;
            text-align: left;
            color: #3773B3;
            margin-bottom: 12px;

            @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                margin-bottom: 15px;
                margin-top: 4px;
            }
        }

        &-text {
            font-size: 20px;
            font-weight: 500;
            line-height: 34px;
            text-align: left;
            color: #333333;
            margin-bottom: 13px;

            &:last-child {
                margin-bottom: 0;
            }

            @media screen and (min-width: $tablet-middle) and (max-width: $tablet-max) {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
            }


        }


    }
}
</style>