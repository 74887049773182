const data = {
  ru: {
    main: {
      head: {
        date: "5–8 ноября 2024",
        subtitle: "МЕЖДУНАРОДНАЯ НАУЧНАЯ КОНФЕРЕНЦИЯ",
        title: "VII ЕФИМОВСКИЕ ЧТЕНИЯ",
        conf_name: "БРИКС",
        conf_name_add:
          "В ГЛОБАЛЬНОМ И РЕГИОНАЛЬНОМ РАЗВИТИИ: ЭКОНОМИКА, ТОРГОВЛЯ, КАЧЕСТВО ЖИЗНИ",
        info: "Проводится ежегодно с 2018 года. В центре научной дискуссии — актуальные проблемы социально-экономического развития, новые возможности и вызовы, с которыми сталкивается Россия и страны мира.",
      },
      directions: {
        title: "НАПРАВЛЕНИЯ ОБСУЖДЕНИЙ 2024",
        list: [
          {
            icon: "icon1.svg",
            item_name: "ЭКОНОМИКА И УПРАВЛЕНИЕ",
            text: [
              `Россия и БРИКС: партнерство в новых реалиях`,
              `Современные подходы к стратегическому управлению: опыт стран-членов БРИКС`,
              `Вызовы внешней торговли России и стран ЕАЭС в текущих условиях глобальной макроэкономической турбулентности и в контексте расширения БРИКС`,
            ],
          },

          {
            icon: "icon2.svg",
            item_name: "ИНТЕГРАЦИОННЫЕ ПРОЦЕССЫ",
            text: [
              `Взаимное торгово-экономическое сотрудничество стран БРИКС и перспективы его углубления`,
              `Возможности углубления сотрудничества ЕАЭС с БРИКС на уровне объединений и в формате взаимодействия Союза с отдельными партнерами по БРИКС`,
            ],
          },

          {
            icon: "icon3.svg",
            item_name: "ПРАВОВОЕ ОБЕСПЕЧЕНИЕ ИНТЕГРАЦИОННЫХ ПРОЦЕССОВ",
            text: [
              `Юридическое измерение стран БРИКС: общее и уникальное в контексте внешнеэкономической деятельности`,
              `Рассмотрение инвестиционных споров с государством: новые реалии`,
            ],
          },
        ],
      },
      contest_info: {
        subtitle: "КОНКУРС",
        title:
          "МЕЖДУНАРОДНЫЙ КОНКУРС СТУДЕНЧЕСКИХ НАУЧНО-ИССЛЕДОВАТЕЛЬСКИХ РАБОТ",
        link: "Участвовать",
        list: [
          {
            title: "Кто может принять участие",
            text: "Студенты 3-4 курсов бакалавриата и студенты магистратуры без академических задолженностей.",
          },
          {
            title: "Победителям",
            text: "Студенты бакалавриата получат 5 ДОПОЛНИТЕЛЬНЫХ БАЛЛОВ к портфолио для поступления в магистратуру, студенты магистратуры – возможность пройти краткосрочное повышение квалификации в Академии БЕСПЛАТНО.  Победители могут быть РЕКОМЕНДОВАНЫ К УЧАСТИЮ в ежегодной Международной научной конференции «Ефимовские чтения».",
          },
          {
            title: "Сроки",
            text: `Прием работ на Конкурс осуществляется с 12 августа по 1 октября. <br/> Рассмотрение работ и определение победителей – до 20 октября.`,
          },
        ],
        button: "Прислать работу",
      },
      biography: {
        short:
          "Конференция посвящена памяти Анатолия Николаевича Ефимова, доктора экономических наук, профессора, члена-корреспондента АН СССР (1964 г.), академика АН СССР (1970 г.), заместителя академика-секретаря отделения экономики АН СССР (1971–1975 гг.), лауреата Государственной премии СССР. ",
        bio: [
          "Анатолий Николаевич Ефимов — первый директор НИЭИ при Госплане СССР. На этой должности проработал с 11 августа 1955 г. по 25 июля 1975 г. Его усилиями создавалась школа ученых экономистов, оказавших значительное влияние на экономическую науку и практику управления народным хозяйством в период, когда директивное планирование выступало ведущим инструментом управления экономикой. Важно отметить его особую роль в организации разработки и внедрения в практику планирования системы межотраслевых балансов, в совершенствовании методологии разработки пятилетних народнохозяйственных планов и создании методологии долгосрочного и среднесрочного социально-экономического прогнозирования.",
          "Ученый родился в 1908 г. в г. Троицке Оренбургской губернии (ныне Челябинской области) России. После окончания в 1937 г. Уральского политехнического института им. С.М. Кирова (Свердловск) около 10 лет посвятил преподавательской работе, совмещая ее с научной деятельностью по исследованию промышленного развития Урала. После защиты кандидатской диссертации руководил отделом экономических исследований Уральского филиала АН СССР, где развернул работы по изучению производительных сил и экономики Уральского экономического района. В этот период исследователь подготовил монографию с изложением концепции ускорения технического прогресса в СССР, защитил (1952 г.) докторскую диссертацию, получил звание профессора (1953 г.).",
          "В 1955 г. А.Н. Ефимов был приглашен в Москву возглавить и организовать деятельность научно-исследовательского экономического института (НИЭИ) Госплана СССР – одного из первых научных институтов, созданных в системе Госплана СССР, который сегодня является одним из структурных научных подразделений Всероссийской академии внешней торговли Минэкономразвития России - Институт макроэкономических исследований. Работу в НИЭИ (1955-1975 гг.) Анатолий Николаевич совмещал с участием в структурах СЭВ и ООН (1958-1971 гг.), занимаясь вопросами развития межгосударственного сотрудничества.",
          "Основные результаты научной и организационной деятельности А.Н. Ефимова, высоко оценены академическим сообществом и государством. В 1968 году он получил государственную премию за цикл исследований по разработке методов анализа и планирования межотраслевых связей и отраслевой структуры народного хозяйства, построению плановых и отчетных межотраслевых балансов в составе группы ученых. Как руководитель авторитетного института, А.Н. Ефимов проявил поистине уникальные способности заражать, увлекать окружающих своим энтузиазмом, создавать в коллективе атмосферу творчества и единства, убежденности в высокой миссии НИЭИ и сопричастности к решению краеугольных задач развития отечественной экономики.",
          "Сегодня с искренней любовью и уважением к науке его ученики и последователи хранят память, передавая знания и опыт новому поколению молодых ученых.",
        ],
      },
    },
    competition: {
      head: {
        conf_name: "МЕЖДУНАРОДНАЯ НАУЧНАЯ КОНФЕРЕНЦИЯ",
        conf_name_add: "VII ЕФИМОВСКИЕ ЧТЕНИЯ",
        subtitle: `Международный конкурс студенческих научно-исследовательских работ`,
        title:
          "«БРИКС в глобальном и региональном развитии: роль, место, перспективы, проблемы»",
        date: "Подача заявок до 1 октября 2024",
        invite: "Приглашаем студентов принять участие в конкурсе эссе",
        rules: "Правила участия и оформления эссе",
      },

      rules: {
        title: "ПРИГЛАШАЕМ СТУДЕНТОВ ПРИНЯТЬ УЧАСТИЕ В КОНКУРСЕ ЭССЕ",
        about: [
          {
            title: "Кто может принять участие",
            text: [
              "Студенты 3-4 курсов бакалавриата и студенты магистратуры без академических задолженностей.",
            ],
          },

          {
            title: "Победителям",
            text: [
              "Студенты бакалавриата получат 5 ДОПОЛНИТЕЛЬНЫХ БАЛЛОВ к портфолио для поступления в магистратуру, студенты магистратуры возможность пройти краткосрочное повышение квалификации в Академии БЕСПЛАТНО.  Победители могут быть РЕКОМЕНДОВАНЫ К УЧАСТИЮ в ежегодной Международной научной конференции «Ефимовские чтения».",
            ],
          },

          {
            title: "Сроки",
            text: [
              "Прием работ на Конкурс осуществляется с 12 августа по 1 октября.",
              "Рассмотрение работ и определение победителей – до 20 октября.",
            ],
          },
        ],
        requirements: {
          title: "Требования к работам",
          text: [
            `<b>Язык:</b> эссе принимаются на русском и английском языках.`,
            `<b>Объем:</b> рекомендуемый объем эссе до 3 000 слов, не включая таблицы, диаграммы, ссылки и список литературы.`,
            `<b>Использование источников:</b> письменная работа должна основываться на достоверных источниках информации, в том числе — академической литературе, журналах и других авторитетных источниках. Необходимо правильно цитировать используемые источники и предоставить ссылки на них.`,
            `<b>Аналитический подход:</b> студенты должны проанализировать тему, рассмотреть различные точки зрения и представить свою собственную аргументацию на основе полученных данных.`,
          ],
        },

        design: {
          title: "Правила оформления эссе",
          text: [
            `Эссе представляется в электронном виде в формате MS Word и должны быть названы «Конкурс_Фамилияучастника».`,
            `Объём научно-исследовательской работы не должен превышать 3 000 слов. Предпочтительный шрифт — Times New Roman, 12 пт, одинарный межстрочный интервал, абзацный отступ 1,25.`,
            `На титульном листе должны быть указаны название работы, имя автора и наименование образовательной организации. Все страницы должны быть пронумерованы.`,
            `В эссе должен быть приведен библиографический список, оформленный в соответствии со стандартами ведущих международных и российских научных журналов. Все сокращения и специальные обозначения должны быть расшифрованы, а рисунки и таблицы должны быть подписаны и пронумерованы.`,
          ],
        },
        links: {
          mail: 'Задать вопрос',
          download: 'Положение о конкурсе'
        }
      },

      registration: {
        title: "ОТПРАВИТЬ ЭССЕ",
        placeholders: {
          surname: "Фамилия *",
          name: "Имя *",
          patronym: "Отчество",
          university: "Наименование учебного заведения *",
          faculty: "Факультет *",
          course: "Курс обучения *",
          year: "Год выпускa *",
          country: "Страна *",
          city: "Город проживания *",
          email: "E-mail *",
          phone: "Контактный телефон",
          text: "Комментарии",
        },

        messages: {
          empty: "Поле обязательно для заполнения",
          email: "E-mail должен быть вида example@domain.com",
          year: "Допускаются только цифры",
          phone:
            "Допускаются только + и цифры. Длина номера должна быть от 9 до 15 символов.",
        },
      },
    },

    footer: {
      name: "Всероссийская академия внешней торговли",
      adress:
        "119285, Россия, Москва, Воробьевское шоссе, 6А +7 499 143 23 60 доб 1504 <span>efimov-konf@vavt.ru</span>",
    },
    text: "русский",
  },

  en: {
    main: {
      head: {
        date: "5–8 November 2024",
        subtitle: "",
        title: "VII INTERNATIONAL EFIMOV MEMORIAL CONFERENCE",
        conf_name: "BRICS",
        conf_name_add:
          "IN GLOBAL AND REGIONAL DEVELOPMENT: ECONOMY, TRADE, QUALITY OF LIFE",
        info: "The International Efimov Memorial Conference is held annually from 2018. The scientific discussion is focused on the current problems of socio-economic development, new challenges and opportunities facing Russia and other countries. The conference includes reports and expert discussions as well as presentation of the results of scientific research in the field of economics and law.",
      },
      directions: {
        title: "CONFERENCE TOPICS 2024",
        list: [
          {
            icon: "icon1.svg",
            item_name: "ECONOMICS AND MANAGEMENT",
            text: [
              `The Russian economy as part of the BRICS: partnership in new realities`,
              `Modern approaches to strategic management: the experience of the BRICS member countries`,
              `The challenges of foreign trade between Russia and the EAEU countries in the current conditions of global macroeconomic turbulence and in the context of the expansion of the BRICS`,
            ],
          },

          {
            icon: "icon2.svg",
            item_name: "INTEGRATION",
            text: [
              `Mutual trade and economic cooperation of the BRICS countries and prospects for its deepening`,
              `The possibilities of deepening cooperation between the EAEU and the BRICS at the level of associations and in the format of interaction between the Union and individual BRICS partners`,
            ],
          },

          {
            icon: "icon3.svg",
            item_name: "LEGAL",
            text: [
              `The legal dimension of the BRICS countries: general and special in the context of foreign economic activity`,
              `Consideration of investment disputes with the state: new realities`,
            ],
          },
        ],
      },
      contest_info: {
        subtitle: "КОНКУРС",
        title: "INTERNATIONAL ESSAY CONTEST FOR STUDENTS",
        link: "Participate",
        list: [
          {
            title: "Participants",
            text: `The contest is open to the students of bachelor’s and master’s degree programs of the Russian and foreign universities, as well as graduates of the current graduating class. Students must not have any academic debts, including unfinished coursework or failed exams.
            To take part in the contest, you need to register and fill in the form.`,
          },
          {
            title: "Assessment",
            text: `Professional jury consisting of the leading experts in the field of foreign trade will assess the essay using such criteria as its relevance, scientific novelty and familiarity with classical and modern studies in the field of economics. The ability to argue and present ideas in a convincing and logical manner will also be considered.`,
          },
          {
            title: "Open for submissions",
            text: `August 12 – October 20. <br/> The deadline is October 1.`,
          },
        ],
        button: "Send your work",
      },
      biography: {
        short:
          "The conference is dedicated to the memory of Anatoly N. Efimov, Doctor of Economics, Professor, Corresponding Member of the USSR Academy of Sciences (1964), Academician of the USSR Academy of Sciences (1970), Deputy Academician-Secretary of the Economics Department of the USSR Academy of Sciences (1971-1975), Recipient of the State Prize of the USSR.",
        bio: [
          "Anatoly N. Efimov was the first director of the Research and Development Institute under the USSR State Planning Committee. In this position he worked from August 11, 1955 to July 25, 1975. His efforts formed grounds for creation of school of economists who had a significant impact on economic science and practice of managing the national economy at the time when directive planning was the leading tool for managing the economy. It is important to emphasize his great role in organizing the development and implementation of a system of inter-sectorial balances in planning practice, in improving the methodology for developing five-year national economic plans and creating a methodology for long-term and medium-term socio-economic forecasting. ",
          "Anatoly N. Efimov was born in 1908 in the town of Troitsk, Orenburg province (now Chelyabinsk region), Russia. After graduating in 1937 from the Ural Polytechnic Institute named after S.M.Kirov (Sverdlovsk) he devoted about 10 years of his life to teaching, combining it with the academic activities, i.e. study of the industrial development of the Urals. After defending his Candidate thesis, he headed the economic research department of the Ural Branch of the USSR Academy of Sciences, where he launched work on the study of the productive forces and economy of the Ural economic region. During this period the researcher prepared a monograph outlining the concept of accelerating technical progress in the USSR, defended his doctoral thesis (1952), and received the title of professor (1953).",
          "In 1955 Anatoly N. Efimov was invited to Moscow to head and organize the activities of the Research Economic Institute (REI) of the State Planning Committee of the USSR, one of the first scientific institutes created in the system of the State Planning Committee of the USSR. Today the Institute is known as the Institute for Macroeconomic Research and is one of the structural academic divisions of the Russian Foreign Trade Academy of the Ministry of Economic Development of Russia. A.N. Efimov combined his work at the Research Economic Institute (1955-1975) with participation in the structures of the Council for Mutual Economic Assistance and the UN (1958-1971), dealing with the development of interstate cooperation.",
          "The main results of the scientific and organizational activities of A.N. Efimov are highly appreciated by the academic community and the state. In 1968, being a part of a group of scientists, he received a State Prize for research on the development of methods for analysis and planning of inter-sectoral relations and the sectoral structure of the national economy, the construction of planned and reporting inter-sectoral balances. Being a head of a reputable institute, A.N. Efimov demonstrated truly unique abilities to infect and captivate others with his enthusiasm, to create an atmosphere of creativity, unity and conviction in the high mission of the Research Economic Institute and involvement in solving the key tasks of the development of the domestic economy.",
          "Today, his students and followers keep the memory with sincere love and respect for science and pass on knowledge and experience to a new generation of young scientists.",
        ],
      },
    },
    competition: {
      head: {
        conf_name: "",
        conf_name_add: "VII INTERNATIONAL EFIMOV MEMORIAL CONFERENCE",
        subtitle: "International essay contest",
        title:
          "BRICS in global and regional development: role, place, prospects, problems",
        date: "The deadline is October 1",
        invite:
          "To take part in the contest, you need to register and fill in the form.",
        rules: "Rules for participation and essay submission",
      },

      rules: {
        title: "WE INVITE STUDENTS TO PARTICIPATE IN THE ESSAY CONTEST",
        about: [
          {
            title: "Essay contest",
            text: [
              `The Russian Foreign Trade Academy of the Ministry of Economic Development of the Russian 
              Federation announces an essay contest to support young and talented researchers in the field of 
              economics and international trade.`,
              `This year's theme is <b>«BRICS in global and regional development: role, place, prospects and 
              problems»</b>. We invite participants to express their opinion on the role and place of BRICS in the 
              modern world.
              The essay should be original and present the results of your own research with concrete examples 
              and facts illustrating the ideas discussed.`,
            ],
          },

          {
            title: "Participants",
            text: [
              `The contest is open to the students of bachelor’s and master’s degree programs of the Russian and 
              foreign universities, as well as graduates of the current graduating class. Students must not have 
              any academic debts, including unfinished coursework or failed exams.`,
              `To take part in the contest, you need to register and fill in the form.`,
            ],
          },

          {
            title: "Assessment",
            text: [
              `Professional jury consisting of the leading experts in the field of foreign trade will assess the essay 
              using such criteria as its relevance, scientific novelty and familiarity with classical and modern 
              studies in the field of economics. The ability to argue and present ideas in a convincing and logical 
              manner will also be considered.`,
            ],
          },

          {
            title: "Open for submissions",
            text: ["August 12 – October 20.", "The deadline is October 1."],
          },
        ],

        requirements: {
          title: "Requirements",
          text: [
            `<b>Language:</b> essays will be accepted in Russian and English.`,
            `<b>Length:</b> the recommended length of the essay is up to 3,000 words, excluding tables, diagrams, 
              references and bibliography.`,
            `<b>Use of sources:</b> the essay should be based on reliable sources of information, including academic 
              literature, journals and other authoritative sources. Sources used should be properly cited and 
              referenced.`,
            `<b>Analytical approach:</b> students should analyze the topic, consider different points of view and 
              present their own arguments based on the data obtained.`,
          ],
        },

        design: {
          title: "Essay Design Guidelines",
          text: [
            `The essay is submitted electronically in MS Word format and should be titled "Contest_Name of 
            the participant".`,
            `The length of the essay should not exceed 3,000 words. The preferred font is Times New Roman, 
            12 pt, single-spaced, paragraph indent 1.25.`,
            `The title page should include the title of the work, the name of the author and the name of the 
            educational institution. All pages should be numbered.`,
            `The essay should include a bibliography in accordance with the standards of the leading 
            international and Russian scientific journals. All abbreviations and special designations should be 
            deciphered, figures and tables should be signed and numbered.`,
          ],
        },

        links: {
          mail: 'Ask a question',
          download: 'Rules & Policies'
        }
      },

      registration: {
        title: "SEND YOUR ESSAY",
        placeholders: {
          surname: "Surname *",
          name: "Name *",
          patronym: "",
          university: "University *",
          faculty: "Faculty *",
          course: "Course *",
          year: "Year of graduation *",
          country: "Country *",
          city: "City *",
          email: "E-mail *",
          phone: "Phone number",
          text: "Additional information",
        },

        messages: {
          empty: "This field can not be empty",
          email: "E-mail must be like example@domain.com",
          year: "Only numbers are allowed",
          phone:
            "Only + and numbers are allowed. The length of the number should be between 9 and 15 characters.",
        },
      },
    },

    footer: {
      name: "Russian Foreign Trade Academy",
      adress:
        "119285, 6А Vorobiyovskoye Shosse , Russia, Moscow +7 499 143 23 60 ext 1504 <span>efimov-konf@vavt.ru</span>",
    },
    text: "english",
  },
};

export default data;
